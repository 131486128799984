import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const PropertyMapComponentStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "open",
})(({ theme, open }) => ({
  outline: "1px solid red",
  overflow: "hidden",
  position: "relative",
}));

const WholeMapComponentWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "mapFullScreen",
})(({ theme, fullscreen, height }) => ({
  margin: "0  -15px 0 -15px",
  position: "relative",
  overflow: "hidden",
  height: `${height - 175}px`,
  "&.map_fullscreen_toggle": {
    position: "fixed",
    zIndex: 10000,
    top: 0,
    right: "15px",
    left: "15px",
    bottom: 0,
    height: "100%",
  },

}));

const FullScreenButton = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "mapFullScreen",
})(({ theme, mapFullScreen }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "40px",
  height: "40px",
  boxShadow: "0px 0px 5px 4px rgba(0,0,0,0.10)",
  position: "absolute",
  top: "20px",
  right: "20px",
  backgroundColor: "#fff",
  zIndex: mapFullScreen ? 999 : 1,
  cursor: "pointer",
}));

const SwiperCardsWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "visible",
})(({ theme, visible }) => ({
  position: "absolute",
  width: theme.direction == "rtl" ? "404px" : "385px",
  left: "50%",
  height: "120px",
  bottom: 0,
  zIndex: 9,
  transform: "translateX(-50%)",
  margin: "20px auto",
  display: visible ? "block" : "none",
  "& .swiper-button-next": {
    backgroundColor: "#fff",
    width: "28px",
    height: "28px",
    borderRadius: "50%",
    right: 0,
    top: "50%",
    transform: "translateY(50%)",
    // boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.25)",
  },
  "& .swiper-button-next:after": {
    fontSize: "15px",
    marginLeft: "5px",
    color: "#000",
  },
  "& .swiper-button-prev": {
    backgroundColor: "#fff",
    width: "28px",
    height: "28px",
    borderRadius: "50%",
    left: 0,
    top: "50%",
    transform: "translateY(50%)",
    // boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.25)",
  },
  "& .swiper-button-prev:after": {
    fontSize: "15px",
    marginRight: "5px",
    color: "#000",
  },
  "& .swiper-button-prev.swiper-button-disabled": {
    opacity: 0.8,
  },
  "& .swiper-button-next.swiper-button-disabled": {
    opacity: 0.8,
  },
}));

const ButtonMapSlider = styled("button", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "visible",
})(({ theme, visible }) => ({
  display: 'flex',
  justifyContent: "center",
  alignItems: 'center',
  borderRadius: '50%',   // Make the button circular
  height: '1.7rem',
  width: '1.7rem',
  marginLeft: '0.4rem',
  marginRight: '0.4rem',
  marginTop: '3rem',
  border: 'none',
  size: 'small',
  backgroundColor: '#FFFFFF',  // Set the background color to white
  '&:hover': {
    backgroundColor: 'lightgray', // Add a different background color on hover if desired
  },

}));

const ButtonListView = styled("button", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "visible",
})(({ theme, visible }) => ({
  display: 'flex',
  border: 'none',
  fontSize: '1.2rem',
  textAlign: 'center',
  width: '12rem',
  height: '2.5rem',
  margin: 'auto',
  justifyContent: 'space-evenly ', // Center horizontally
  alignItems: 'center', // Center vertically
  marginTop: 10,
  borderRadius: '20px',
  backgroundColor: '#00BEAD',
  color: 'white',
  marginTop: '20px'
}));



const ResultCountOverlay = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  backgroundColor: 'white',
  lineHeight: '19px',
  fontSize: '16px',
  fontWeight: '600',
  display: "flex",
  position: "absolute",
  zIndex: 2,
  justifyContent: "center",
  color: theme.palette.primary.main,
  bottom: "30px",
  left: "auto", // Set left to "auto" to allow the use of the right property
  right: "58px",
  width: '300px',
  height: '40px',
  justifyItems: 'center',
  alignItems: 'center',
  alignContent: 'center',
  boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.25)",
  borderRadius: '50px',
  [theme.breakpoints.up("md")]: {
    bottom: "20px",
  },
}));


const ResultCountMinOverlay = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  backgroundColor: 'white',
  fontSize: '16px',
  fontWeight: '600',
  display: "flex",
  lineHeight: '19px',
  position: "absolute",
  zIndex: 2,
  justifyContent: "center",
  color: theme.palette.primary.main,
  bottom: "30px",
  left: "auto", // Set left to "auto" to allow the use of the right property
  right: "58px", // Change this to the desired right value
  width: '80px',
  height: '40px',
  justifyItems: 'center',
  alignItems: 'center',
  alignContent: 'center',
  boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.25)",
  borderRadius: '50px',
  [theme.breakpoints.up("md")]: {
    bottom: "20px",
  },
}));


const FilterButtonWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "isSearchExpand",
})(({ theme, isSearchExpand }) => ({
  display: "flex",
  position: "absolute",
  zIndex: 1000,
  justifyContent: "center",
  top: "50px",
  left: "50%",
  transform: "translateX(-50%)",
  alignItems: 'center',
  alignContent: 'center',
 
}));



export {
  PropertyMapComponentStyled,
  SwiperCardsWrapper,
  WholeMapComponentWrapper,
  FullScreenButton,
  ButtonMapSlider,
  ResultCountMinOverlay,
  ResultCountOverlay,
  FilterButtonWrapper,
  ButtonListView,
};
