import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { RiArrowGoBackLine } from "react-icons/ri";
import { RiDirectionFill } from "react-icons/ri";

const PropertyMapViewStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  margin: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
    -15
  )} 0 ${theme.typography.pxToRem(-15)}`,
  position: "relative",
  zIndex: 1,
  overflow: "hidden",
  height: "85vh",
  "&.map_fullscreen_toggle": {
    // position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 1000,
    height: "100vh",
    margin: 0,
    // height: "100%",
  },
}));

const BackIcon = styled(RiArrowGoBackLine, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  fontSize: "20px",
}));

const DirectionIcon = styled(RiArrowGoBackLine, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  fontSize: "10px",
  color: theme.palette.primary.main,
}));

const MapWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  height: "80vh",
  position: "relative",
}));

const NearByWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  position: "absolute",
  zIndex: 1010,
  justifyContent: "center",
  top: "60px",
  left: "50%",
  transform: "translateX(-50%)",
  [theme.breakpoints.up("md")]: {
    top: "20px",
  },
}));

const InfoWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  position: "absolute",
  zIndex: 2,
  justifyContent: "center",
  bottom: "60px",
  left: "50%",
  transform: "translateX(-50%)",
  [theme.breakpoints.up("md")]: {
    bottom: "20px",
  },
}));

const NearByButton = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "active",
})(({ theme, active }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: active ? theme.palette.primary.main : "#fff",
  borderRadius: "9px",
  padding: "8px",
  cursor: "pointer",
  boxShadow: "1px 1px 10px 5px rgba(0,0,0,0.1)",
  transition: "all 0.2s ease",
  [theme.breakpoints.up("sm")]: {
    padding: "10px",
  },
  [theme.breakpoints.up("md")]: {
    padding: "14px",
  },
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
  "& svg": {
    transition: "all 0.2s ease",
    color: active ? "#fff" : "#000",
  },
  "&:hover svg": {
    color: "#fff",
  },
}));

const PropertyCardsWrapper = styled("div", {
  shouldForwardProp: (prop) =>
    isPropValid(prop) && prop !== "visible" && prop !== "navigationVisible",
})(({ theme }) => ({
  position: "absolute",
  width: "365px",
  left: "50%",
  height: "120px",
  bottom: 0,
  zIndex: 9,
  transform: "translateX(-50%)",
  margin: "20px auto",
  display: "block",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const FullScreenButton = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "mapFullScreen",
})(({ theme, mapFullScreen }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "40px",
  height: "40px",
  boxShadow: "0px 0px 5px 4px rgba(0,0,0,0.10)",
  position: "absolute",
  top: "20px",
  right: "20px",
  backgroundColor: "#fff",
  zIndex: mapFullScreen ? 999 : 1,
  cursor: "pointer",
}));

export {
  PropertyMapViewStyled,
  MapWrapper,
  NearByWrapper,
  NearByButton,
  BackIcon,
  DirectionIcon,
  PropertyCardsWrapper,
  InfoWrapper
};
