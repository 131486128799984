import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { fontSize } from "@mui/system";

const PropertyInfoDrawerStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "open",
})(({ theme, open }) => ({
  position: "absolute",
  zIndex: 99,
  backgroundColor: "#fff",
  left: 0,
  //right: 0,
  top: 0,
  bottom: 0,
  boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.1)",
  transition: "transform 0.2s ease",
  transform: open ? "translateX(100)" : "translateX(-100%)",
  display: "none",
  [theme.breakpoints.up("md")]: {
    display: "block",
  },

  // overflowY: "scroll",
}));

const Contents = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  // height: "100%",
  // overflowY: "auto",
  // overflowX: "hidden",
  width: "100%",
  // minWidth: "325px",
  flexDirection: "column",
  marginBottom: "20px",
  "&:not(:last-child)": {
    // borderBottom: `1px solid #F0F0F0`,
  },
  [theme.breakpoints.up("md")]: {
    // minWidth: "650px",
    flexDirection: "row",
    marginBottom: 0,
  },
}));

const LeftBlock = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  width: "100%",
  maxWidth: "330px",
  padding: "0 20px 0 10px",
}));

const RightBlock = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  width: "100%",
  maxWidth: "325px",
  padding: "0 10px 0px 10px",
  [theme.breakpoints.up("md")]: {
    padding: "35px 10px",
  },
}));

const RightBlockHeader = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  textAlign: "center",
  marginBottom: "25px",
}));

const PriceBlock = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}));
const PriceBlockLeft = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({}));

const PriceBlockRight = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  marginTop: "20px",
  marginBottom: "20px",
}));

const DrawerAnchor = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "drawerOpen",
})(({ theme, drawerOpen }) => ({
  backgroundColor: "#fff",
  position: "absolute",
  width: "50px",
  height: "50px",
  borderRadius: "50%",
  display: "flex",
  justifyContent: drawerOpen ? "center" : "flex-end",
  alignItems: "center",
  zIndex: 1,
  top: "50%",
  right: "-25px",
  cursor: "pointer",
  transform: "translateY(-50%)",
  boxShadow: "0px 0px 8px 2px rgba(0,0,0,0.1)",
}));

const DrawerAnchorIconLtr = styled(FiChevronRight, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "open",
})(({ theme, open }) => ({
  color: theme.palette.primary.main,
  fontSize: "30px",
  transition: "transform 0.2s ease",
  transform: open ? "scaleX(-1)" : "scaleX(1)",
}));

const DrawerAnchorIconRtl = styled(FiChevronLeft, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "open",
})(({ theme, open }) => ({
  color: theme.palette.primary.main,
  fontSize: "30px",
  transition: "transform 0.2s ease",
  transform: open ? "scaleX(-1)" : "scaleX(1)",
}));

const FacilitiesWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme, open }) => ({
  "& ul": {
    display: "flex",
    flexWrap: "wrap",
    margin: "-5px",
    listStyleType: "none",
  },
}));

const Facility = styled("li", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "disabled",
})(({ theme, disabled }) => ({
  //opacity: disabled ? 0.4 : 1,
  display: disabled ? "flex" : "none",
  //display: "flex",
  alignItems: "center",
  border: `1px solid ${theme.palette.brownGray.main}`,
  padding: "4px 8px",
  margin: "5px",
  borderRadius: "8px",
  "& img": {
    maxHeight: "14px",
    marginRight: "5px",
  },
}));

const Divider = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "disabled",
})(({ theme, disabled }) => ({
  borderBottom: `1px solid #F0F0F0`,
  width: "230px",
  margin: "20px auto",
}));

export {
  PropertyInfoDrawerStyled,
  DrawerAnchor,
  DrawerAnchorIconLtr,
  DrawerAnchorIconRtl,
  LeftBlock,
  RightBlock,
  PriceBlock,
  PriceBlockLeft,
  PriceBlockRight,
  Contents,
  RightBlockHeader,
  FacilitiesWrapper,
  Facility,
  Divider,
};
