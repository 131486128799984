import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { NoItemStyled } from "./style";

const NoItem = ({
  title = "no-properties-available",
  centered,
  icon = null,
  heading = null,
}) => {
  const { t } = useTranslation("common");
  return (
    <NoItemStyled centered={centered}>
      {icon && icon}
      {heading && (
        <Typography
          variant="body2"
          size="big"
          sx={{ color: "midGray.main", textAlign: "center", my: "10px" }}
        >
          {t(heading)}
        </Typography>
      )}
      <Typography
        variant="body1"
        sx={{ color: "midGray.main", textAlign: "center", lineHeight: 1.3 }}
      >
        {t(title)}
      </Typography>
    </NoItemStyled>
  );
};

export default NoItem;
