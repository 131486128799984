import { useCallback, useEffect, useState } from 'react';
import {
    PropertyInfoDrawerStyled,
    DrawerAnchor,
    DrawerAnchorIconLtr,
    DrawerAnchorIconRtl,
    LeftBlock,
    PriceBlock,
    Divider,
    Contents,
} from "./style";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRouter } from 'next/router';
import { useTranslation } from "next-i18next";
import { useAxios } from '../../../../hooks/axios';
import Cookies from 'js-cookie';
import PropertyCard from "../../../PropertyCard/PropertyCard";
import { CircularProgress, Stack } from '@mui/material';
import { CustomButton } from '../../../common/Buttons/Buttons';

const PropertyInfoDrawerMap = ({
    drawerOpen,
    setDrawerOpen,
    setMapDrawer,
    listingType,
    propertyType,
    highlight
}) => {

    const axios = useAxios();
    const { locale } = useRouter();
    const router = useRouter();
    const { t } = useTranslation("common");

    const [offset, setOffset] = useState(0);
    const [perPage, setPerPage] = useState(5);
    const [items, setItems] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        initialItems();
    }, []);

    useEffect(() => {
        if (listingType == "rent" || listingType == "sale") {
            setOffset(0);
            setItems([]);
            setHasMore(true);
            initialItems();
        }
    }, [listingType]);
    const initialItems = async () => {
        setFetching(true);

        try {
            const { data } = await axios.post("/search/v1/listings/mapfilters", {
                page: 0,
                pageSize: 10,
                listingTypeFilter: listingType,
                propertyType: propertyType == "null" ? null : propertyType,
                orderBy: "price desc",
                defaultCity: Cookies.get("city_id"),
            },
                {
                    headers: {
                        "Accept-Language": router?.locale == "en" ? "en-US" : "ar-SA en-US",
                    },
                });

            if (data.data.results.length > 0) {
                setItems(data.data.results);
                setOffset(prev => prev + 1);
            }
        } finally {
            setFetching(false);
        }
    };
    const fetchItems = async () => {
        setFetching(true);
        try {
            const { data } = await axios.post("/search/v1/listings/mapfilters", {
                page: offset,
                pageSize: perPage,
                listingTypeFilter: listingType,
                propertyType: propertyType == "null" ? null : propertyType,
                orderBy: "price desc",
                defaultCity: Cookies.get("city_id"),
            },
                {
                    headers: {
                        "Accept-Language": router?.locale == "en" ? "en-US" : "ar-SA en-US",
                    },
                });

            if (data.data.results.length > 0) {
                setItems(prevItems => [...prevItems, ...data.data.results]);
                setOffset(prev => prev + 1);
                setHasMore(true);
            } else {
                setHasMore(false);
            }
        } finally {
            setFetching(false);
        }
    };

    // const fetchItems = useCallback(async () => {
    //     setFetching(true);

    //     try {
    //         const { data } = await axios.post("/search/v1/listings/mapfilters", {
    //           page: offset,
    //           pageSize: perPage,
    //           listingTypeFilter: listingType == "buy" ? "sale" : listingType,
    //           propertyType: propertyType == "null" ? null : propertyType,
    //           orderBy: "price desc",
    //           defaultCity: Cookies.get("city_id"),
    //         },
    //         {
    //           headers: {
    //             "Accept-Language": router?.locale == "en" ? "en-US" : "ar-SA en-US",
    //           },
    //         });

    //         if(data.data.results.length > 0) {
    //             setItems([...items, ...data.data.results]);
    //             setOffset(prev => prev + 1);
    //             setHasMore(true);
    //         }else {
    //             setHasMore(false);
    //         }
    //     } finally {
    //         setFetching(false);
    //     }
    // }, []);

    return (
        <PropertyInfoDrawerStyled open={drawerOpen}>

            <div style={{ overflowY: "scroll", height: "100%" }}>
                <InfiniteScroll
                    dataLength={items.length}
                    next={fetchItems}
                    hasMore={hasMore}
                    loader={<div />}
                    endMessage={<div />}
                >
                    {items.map((property, k) => {
                        return (
                            <Contents key={`side-content-${k}`} style={{ width: 330 }}>
                                <LeftBlock>
                                    <div style={{height: '80%'}}>
                                        <PropertyCard data={property} />
                                    </div>
                                    <br/>
                                    <PriceBlock>
                                        <Stack direction={"row"} spacing={1} sx={{ ml: "10px" }}>
                                            <CustomButton
                                                variant="contained"
                                                onClick={() => {
                                                    let propertySlug = property?.title?.replace(/,?\s+/g, '-').toLowerCase();
                                                    if (property?.documentType === 1) {
                                                        router?.push({
                                                            pathname: `/project/${property?.id}`,
                                                        });
                                                    } else {
                                                        router?.push({
                                                            pathname: `/property-details/${propertySlug}-${property?.advertisementNumber}`,
                                                        });
                                                    }

                                                }}
                                            >
                                                {t("more")}
                                            </CustomButton>
                                            <CustomButton
                                                variant="contained"
                                                onClick={() => {
                                                    highlight(property);
                                                    setDrawerOpen(false);
                                                }}
                                            >
                                                {t("view-on-map")}
                                            </CustomButton>
                                        </Stack>
                                    </PriceBlock>
                                    <Divider />
                                </LeftBlock>
                            </Contents>
                        );
                    })}
                </InfiniteScroll>
                {fetching ?
                    <center style={{ marginTop: 10, width: 330 }}>
                        <CircularProgress />
                    </center>
                    : null}
            </div>
            <DrawerAnchor
                onClick={() => {
                    setDrawerOpen(!drawerOpen);
                    setMapDrawer(false);
                }}
                drawerOpen={drawerOpen}
            >
                {locale == "en" ? (
                    <DrawerAnchorIconLtr open={drawerOpen} />
                ) : (
                    <DrawerAnchorIconRtl open={drawerOpen} />
                )}
            </DrawerAnchor>
        </PropertyInfoDrawerStyled>
    )
}

export default PropertyInfoDrawerMap;