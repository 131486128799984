import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const NoItemStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "centered",
})(({ theme, centered }) => ({
  display: "flex",
  justifyContent: centered ? "center" : "flex-start",
  height: '340px',
  alignItems: "center",
  padding: `${theme.typography.pxToRem(30)} ${theme.typography.pxToRem(10)}`,
  flexDirection: "column",
}));

const IconWrapper = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "centered",
})(({ theme, centered }) => ({}));

export { NoItemStyled, IconWrapper };
